<template>
  <article class="existing-article" v-html="block.data"></article>
</template>

<style>
.existing-article h1,
.existing-article h2,
.existing-article h3,
.existing-article h4,
.existing-article h5,
.existing-article h6 {
  clear: both;
  text-transform: uppercase;
}
.existing-article h2 {
  text-align: center;
}
.existing-article ul,
.existing-article ol {
  margin-left: 5%;
  margin-right: 2%;
}
.existing-article ul > li {
  list-style: disc;
  font-weight: lighter;
  font-size: larger;
}
.existing-article li img {
  margin: 0 15px 0 0;
}
.existing-article .tile-box {
  clear: both;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.existing-article .tile {
  width: 80%;
  padding: 0.5rem;
}
.existing-article .tile p {
  margin-left: 0;
}
.existing-article .tile-icon > img {
  max-width: 250px;
  border: 1px solid lightgray;
}
.existing-article .tile-title {
  font-size: 24px;
  text-transform: uppercase;
}
.existing-article .step-box {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  margin-left: 5%;
  margin-right: 2%;
  padding: 10px 0px;
  border-bottom: 1px solid lightgray;
}
.existing-article .step-box .count {
  font-size: 26px;
  width: 3rem;
}
.existing-article .step-box .count > h4 {
  margin: 0;
}
.existing-article .step-box .desc {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  margin: auto 0;
}
.existing-article .step-box ul,
.existing-article .step-box ol {
  margin-left: 3%;
}
.existing-article img {
  object-fit: cover;
  display: block;
  height: auto;
  max-width: 100%;
}
.existing-article img.small {
  max-width: 150px;
}
.existing-article .figure {
  padding: 0.2rem;
  border: 1px solid lightgray;
}
.existing-article .figure.small {
  max-width: 350px;
}
.existing-article .float-right,
.existing-article .float-left {
  clear: both;
  margin: 0.2rem;
  max-width: 35%;
  height: auto;
}
.existing-article .float-right {
  margin-left: 0.4rem;
  margin-right: 0;
}
.existing-article .float-left {
  margin-left: 0;
  margin-right: 0.4rem;
}
.existing-article img.float-right,
.existing-article img.float-left,
.existing-article figure.float-right img,
.existing-article figure.float-left img {
  max-height: 250px;
}

@media (max-width: 960px) {
  .existing-article .tile {
    width: 100%;
    margin-left: 25px;
    padding: 0;
    border: none;
  }
}
@media (max-width: 600px) {
  .existing-article .float-right,
  .existing-article .float-left {
    max-width: 45%;
  }
  .existing-article .tile {
    flex-direction: column;
    padding: 0;
  }
  .existing-article .tile-content {
    margin-bottom: 0px;
    padding-left: 0 !important;
  }
}
@media (min-width: 600px) {
  h2 {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 10px;
  }
  .existing-article h2:before,
  .existing-article h2:after {
    display: block;
    background: black;
    flex: 1;
    flex-basis: 10%;
    content: ' ';
    height: 2px;
  }
}
</style>

<script>
export default {
  name: 'BlockExistingArticle',
  props: {
    block: Object,
  },
  methods: {
    logClick(event) {
      if (!event.target.classList.contains('btn-track')) return;
      let payload = {
        action: 'click',
        type: event.target.getAttribute('data-type'),
        typeDetail: event.target.getAttribute('data-detail'),
        uri: event.target.getAttribute('data-uri'),
      };
      this.$emit('logAction', payload);
    },
  },
  mounted() {
    this.$el.addEventListener('click', this.logClick);
  },
  data() {
    return {};
  },
};
</script>
